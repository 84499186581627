
import React, { useEffect, useContext, useState } from 'react'
import { RegisterContext } from '../../context/RegisterContext'

function AddPlan(props: any) {
    const { info } = props
    const setAddPlan = useContext(RegisterContext).setAddPlan

    const [state, setState] = useState()


    useEffect(() => {
        let currentState = JSON.parse(localStorage.getItem('studio'))
        if (typeof currentState == 'object') {
            setState(currentState?.attributes?.state?.data?.attributes?.Name)
        } else {
            setState(currentState)
        }
    }, [localStorage.getItem('studio')])


    return (
        <div onClick={() => setAddPlan(false)}
            style={{ zIndex: 9999999999, backgroundColor: 'rgba(0, 0, 0, .4)' }}
            className='fixed top-0 left-0  w-full h-full '>
            {info !== '' && state !== '' && (
                <div style={{ zIndex: 999999999999999 }}
                    className='fixed  w-full rounded-lg overflow-hidden left-1/2 h-[100vh] top-1/2  transform -translate-x-1/2 -translate-y-1/2    z-40'>
                    <div className='lg:w-[768px] w-full mx-auto mt-[40px] rounded-tl-md rounded-tr-md border-b relative border-b-gray h-[70px] bg-[#F8F9FA]'>
                        <div
                            style={{ zIndex: 999999999999999 }}
                            className='absolute cursor-pointer right-4 top-4'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M19 5L5 19" stroke="#545459" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M5 5L19 19" stroke="#545459" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                    <>
                        {state == 'NSW' && (
                            <>
                                {info == 'Class 10 Pack' || info == 'Casual Class'
                                    ? <iframe id="mindbody_branded_web_cart_modal"
                                        className='h-[86%] rounded-bl-lg rounded-br-lg mx-auto lg:w-[768px] w-full'
                                        src={`https://cart.mindbodyonline.com/sites/106809/cart/add_service?mbo_item_id=${info == 'Casual Class' ? '10102' : '10103'}&source=pricing-link`}
                                    ></iframe>
                                    : <iframe id="mindbody_branded_web_cart_modal"
                                        className='h-[86%] rounded-bl-lg rounded-br-lg mx-auto lg:w-[768px] w-full'
                                        src={`https://cart.mindbodyonline.com/sites/106809/cart/add_contract?mbo_item_id=${info == 'Express' && '110' || info == 'Flexi' && '144' || info == 'Unlimited' && '142'}&source=contract-link`}
                                    ></iframe>
                                }
                            </>
                        )}

                        {state == 'ACT' && (
                            <>
                                {info == 'Class 10 Pack' || info == 'Casual Class'
                                    ? <iframe id="mindbody_branded_web_cart_modal"
                                        className='h-[86%] rounded-bl-lg rounded-br-lg mx-auto lg:w-[768px] w-full'
                                        src={`https://cart.mindbodyonline.com/sites/110588/cart/add_service?mbo_item_id=${info == 'Casual Class' ? '10102' : '10103'}&source=pricing-link`}
                                    ></iframe>
                                    : <iframe id="mindbody_branded_web_cart_modal"
                                        className='h-[86%] rounded-bl-lg rounded-br-lg mx-auto lg:w-[768px] w-full'
                                        src={`https://cart.mindbodyonline.com/sites/110588/cart/add_contract?mbo_item_id=${info == 'Express' && '110' || info == 'Flexi' && '124' || info == 'Unlimited' && '121'}&source=contract-link`}
                                    ></iframe>
                                }
                            </>
                        )}

                        {state == 'VIC' && (
                            <> {info == 'Class 10 Pack' || info == 'Casual Class'
                                ? <iframe id="mindbody_branded_web_cart_modal"
                                    className='h-[86%] rounded-bl-lg rounded-br-lg mx-auto lg:w-[768px] w-full'
                                    src={`https://cart.mindbodyonline.com/sites/12000/cart/add_service?mbo_item_id=${info == 'Casual Class' ? '10102' : '10103'}&source=pricing-link`}
                                ></iframe>
                                : <iframe id="mindbody_branded_web_cart_modal"
                                    className='h-[86%] rounded-bl-lg rounded-br-lg mx-auto lg:w-[768px] w-full'
                                    src={`https://cart.mindbodyonline.com/sites/12000/cart/add_contract?mbo_item_id=${info == 'Express' && '120' || info == 'Flexi' && '124' || info == 'Unlimited' && '122'}&source=contract-link`}
                                ></iframe>
                            }
                            </>
                        )}
                        {state == 'QLD' && (
                            <> {info == 'Class 10 Pack' || info == 'Casual Class'
                                ? <iframe id="mindbody_branded_web_cart_modal"
                                    className='h-[86%] rounded-bl-lg rounded-br-lg mx-auto lg:w-[768px] w-full'
                                    src={`https://cart.mindbodyonline.com/sites/10332/cart/add_service?mbo_item_id=${info == 'Casual Class' ? '10121' : '10144'}&source=pricing-link`}
                                ></iframe>
                                : <iframe id="mindbody_branded_web_cart_modal"
                                    className='h-[86%] rounded-bl-lg rounded-br-lg mx-auto lg:w-[768px] w-full'
                                    src={`https://cart.mindbodyonline.com/sites/10332/cart/add_contract?mbo_item_id=${info == 'Express' && '137' || info == 'Flexi' && '200' || info == 'Unlimited' && '199'}&source=contract-link`}
                                ></iframe>
                            }
                            </>
                        )}
                    </>
                </div>
            )}
        </div>
    )
}

export default AddPlan